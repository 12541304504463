// Code to trigger sticky header
if (jQuery(this).scrollTop() > 100  && jQuery(window).width() >= '768') {  
    jQuery('#navbar-primary-sticky > .container-fluid').css('margin-top', '0px');
    jQuery('#navbar-primary-sticky').css('height', '60px', 'top', '0').fadeIn( 400 );
    jQuery('#pp-mobile-menu').addClass('sticky');  
} else if (jQuery(this).scrollTop() < 100  && jQuery(window).width() >= '768') {  
    jQuery('#navbar-primary-sticky').css('height', '85px', 'top', '-2px').fadeOut( 100 );
    jQuery('#navbar-primary-sticky > .container-fluid').css('margin-top', '9px');
    jQuery('#pp-mobile-menu').removeClass('sticky');

} 

jQuery(window).scroll(function() {
   if (jQuery(this).scrollTop() > 100  && jQuery(window).width() >= '768') {  
        jQuery('#navbar-primary-sticky > .container-fluid').css('margin-top', '0px');
        jQuery('#navbar-primary-sticky').css('height', '60px', 'top', '0').fadeIn( 400 );
        jQuery('#pp-mobile-menu').addClass('sticky');  
   } else if (jQuery(this).scrollTop() < 100  && jQuery(window).width() >= '768') {  
        jQuery('#navbar-primary-sticky').css('height', '85px', 'top', '-2px').fadeOut( 100 );
        jQuery('#navbar-primary-sticky > .container-fluid').css('margin-top', '9px');
        jQuery('#pp-mobile-menu').removeClass('sticky');
   } 
});

// jQuery(window).scroll(function() {
//    if (jQuery(this).scrollTop() > 150){  
//        if(jQuery(window).width() >= '768') {
//            jQuery('#masthead').addClass('affix').removeClass('affix-top');
//        }
//    } else {
//        if(jQuery(window).width() >= '768') {
//            jQuery('#masthead').addClass('affix-top').removeClass('affix');
//        }
//    } 
// });

jQuery( window ).resize(function() {    
    
    if (jQuery(this).scrollTop() > 150){  
       if(jQuery(window).width() >= '768') {
           jQuery('#navbar-primary-sticky').css('height', '60px', 'top', '0');
           jQuery('#pp-mobile-menu').addClass('sticky');  
       }
   } else {
       if(jQuery(window).width() >= '768') {
           jQuery('#navbar-primary-sticky').css('height', '0px', 'top', '-2px');
           jQuery('#pp-mobile-menu').removeClass('sticky');
       }
   }  
});

// fix for header horizontal scrollbar
if(jQuery(window).width() <= '976') {
    //jQuery('#masthead > .container-fluid').addClass('container').removeClass('container-fluid');
} else {
    //jQuery('#masthead > .container').addClass('container-fluid').removeClass('container');
}
if(jQuery('.custom-container').length > 0) {
    jQuery('.custom-container').width(jQuery(window).width()-30);
    if(jQuery(window).width() <= '800') {
        jQuery('.custom-container').css('margin-left',-(jQuery('.content.container').offset().left+10));
    } else {
        jQuery('.custom-container').css('margin-left',-(jQuery('.content.container').offset().left+15));
    }
}



jQuery( window ).resize(function() { 
    if(jQuery(window).width() <= '976') {
        //jQuery('#masthead > .container-fluid').addClass('container').removeClass('container-fluid');
    } else {
        //jQuery('#masthead > .container').addClass('container-fluid').removeClass('container');
    }
    if(jQuery('.custom-container').length > 0) {
        jQuery('.custom-container').width(jQuery(window).width()-30);
        if(jQuery(window).width() <= '800') {
            jQuery('.custom-container').css('margin-left',-(jQuery('.content.container').offset().left+10));
        } else {
            jQuery('.custom-container').css('margin-left',-(jQuery('.content.container').offset().left+15));
        }
    }

});

if(jQuery('.featured_subpages').length > 0) {
    if(jQuery(window).width() <= '752') {
        jQuery('.featured_subpages.container-fluid').addClass('container').removeClass('container-fluid');
    } else {
        jQuery('.featured_subpages.container').addClass('container-fluid').removeClass('container');
    }
}
jQuery( window ).resize(function() { 
    if(jQuery('.featured_subpages').length > 0) {
        if(jQuery(window).width() <= '752') {
            jQuery('.featured_subpages.container-fluid').addClass('container').removeClass('container-fluid');
        } else {
            jQuery('.featured_subpages.container').addClass('container-fluid').removeClass('container');
        }
    }
});